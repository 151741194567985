import { ApplicationController, useDebounce } from 'stimulus-use'

export default class extends ApplicationController {
  static debounces = ['autoSubmit']

  connect() {
    useDebounce(this, { wait: 500 })
  }

  autoSubmit() {
    this.element.requestSubmit()
  }

  redirectAfterSubmit(event) {
    if (event.detail.success) {
      const fetchResponse = event.detail.fetchResponse

      history.pushState(
        { turbo_frame_history: true },
        "",
        fetchResponse.response.url
      )

      Turbo.visit(fetchResponse.response.url)
    }
  }
}
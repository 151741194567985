import 'popper.js';
import 'bootstrap';

require("@rails/ujs").start();

$.ajax({ 
  beforeSend: function(xhr, d) {
    const { sessionId } = $('meta#globals').data();
    if (sessionId && sessionId.length)
      xhr.setRequestHeader('X-Session-Id', sessionId);
  },
})

document.addEventListener('ajax:beforeSend', function(e) {
    const [ xhr ] = e.detail;
    const { sessionId } = $('meta#globals').data();
    if (sessionId && sessionId.length)
        xhr.setRequestHeader('X-Session-Id', sessionId);
});

document.addEventListener("turbo:frame-missing", (event) => {
  const { detail: { response, visit } } = event;
  event.preventDefault();
  visit(response.url);
});

import "@hotwired/turbo-rails"
import { Application } from "@hotwired/stimulus"
const application = Application.start();

import OrderProductCardsController from "./controllers/order_product_cards_controller"
application.register('order-product-cards', OrderProductCardsController);

import ModalController from "./controllers/modal_controller"
application.register('modal', ModalController);

import OrderDetailsController from "./controllers/order_details_controller"
application.register('order-details', OrderDetailsController);

import OrderItemQuantityController from "./controllers/order_item_quantity_controller"
application.register('order-item-quantity', OrderItemQuantityController);

import IntegerFormatController from "./controllers/integer_format_controller"
application.register('integer-format', IntegerFormatController);

import PhoneNumberFormatController from "./controllers/phone_number_format_controller"
application.register('phone-number-format', PhoneNumberFormatController);

import FormController from "./controllers/form_controller"
application.register('form', FormController);

import OrderCheckoutController from "./controllers/order_checkout_controller"
application.register('order-checkout', OrderCheckoutController);

import CloudflareCaptchaController from "./controllers/cloudflare_captcha_controller"
application.register('cloudflare-captcha', CloudflareCaptchaController);

import ToastController from "./controllers/toast_controller"
application.register('toast', ToastController);

import TooltipController from "./controllers/tooltip_controller"
application.register('tooltip', TooltipController);

import DirectUploadController from "./controllers/direct_upload_controller"
application.register('direct-upload', DirectUploadController);

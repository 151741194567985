import * as $ from "jquery";
import { debounce } from 'underscore';
import {ORDER_STATUSES} from "./constants";

export const buildToast = (header, body, delay = 5000, autohide = true, type = 'success') => {
    const $toast = $('<div>', { class: 'toast', id: 'action-toast', role: 'alert', 'data-controller': 'toast' }).append([
        $('<div>', { class: `toast-header text-white ${type === 'success' ? 'bg-success' : 'bg-danger'}` }).append([
            $('<i>', { class: `fas mr-2 ${type === 'success' ? 'fa-check-circle' : 'fa-exclamation-triangle'}` }),
            $('<strong>', { text: header, class: 'mr-auto' }),
            $('<button>', { class: 'close ml-3 text-white', type: 'button' }).attr('data-dismiss', 'toast').append(
                $('<i>', { class: 'fas fa-times fa-xs' })
            )
        ]),
        $('<div>', { class: 'toast-body', text: body }),
    ]);
    $('body').append($toast);
};

export const initRemoveFieldLinks = () => {
    $('.nested-fields a.remove-fields:not(.bound)').addClass('bound').click(function(e) {
        e.preventDefault();
        const $this = $(this),
            $fieldWrapper = $this.closest('.nested-fields'),
            $idInput = $fieldWrapper.find('input.id'),
            id = $idInput.val(),
            $destroyInput = $fieldWrapper.find('input.destroy');

        if (id && id.length) {
            $destroyInput.val('1');
            $fieldWrapper.hide();
        }
        else {
            $fieldWrapper.remove();
        }
        return false;
    });
};

export const initTableDeleteLinks = (table, successMsg = 'Item deleted.', errorMsg = 'Unable to delete item.') => {
    if (!table) return;
    $(table.element).find('a.delete:not(.bound)')
        .addClass('bound')
        .off('ajax:success')
        .on('ajax:success', function(e) {
            const [ data ] = e.detail;
            if (!data || typeof data !== 'object' || !('id' in data)) return;
            const { id } = data;
            table.deleteRow(id);
            buildToast('Success!', successMsg);
        })
        .off('ajax:error')
        .on('ajax:error', function() {
            buildToast('Ooops...', errorMsg, undefined, undefined, 'error');
        });
};

/**
 * General table search for non-ajax tables
 *
 * @param table:            tabulator table
 * @param keys:             optional array of keys to search on
 * @param debounceMs:       optional debounce time (ms)
 * @param container:        optional input parent container
 * @returns {*|jQuery}
 */
export const generalTableSearch = (table, keys = null, debounceMs = 500, container = null) =>
    $(`${container ? `${container} ` : ''}input#table-search`).off('keyup').on('keyup', debounce(function() {
        const q = this.value;
        if (q && q.length) table.setFilter(matchAny, { q, keys });
        else table.clearFilter(true);
    }, debounceMs));

export const generalAjaxTableSearch = (table, debounceMs = 500) =>
    $('input#table-search').on('keyup', debounce(function() {
        const q = this.value;
        // special case 'any' field for general table search
        if (q && q.length) table.setFilter('any', 'like', q);
        else table.clearFilter(true);
    }, debounceMs));

/**
 * General table filter
 *
 * @param data:         row data
 * @param q:            search term
 * @param keys:         optional array of row data keys to search on
 * @returns {boolean}
 */
const matchAny = (data, { q, keys }) => Object.keys(data)
    .filter(key => Array.isArray(keys) && keys.length ? keys.includes(key) : true)
    .map(key => data[key].toLowerCase().includes(q.toLowerCase())).some(Boolean);

export const tablePageLength = (table, container = null) => {
    const $select = $(`${container ? `${container} ` : ''}select#page-length`);
    const initialVal = table.getPageSize();
    $select.val(initialVal);
    $select.find(`option[value="${initialVal}"]`).prop('selected', true);
    $select.find(`option[value!="${initialVal}"]`).prop('selected', false);
    $select.change(function() { table.setPageSize(this.value) })
};

export function showSpinner() {
    const $spinner = $('#preloader-overlay');
    if ($spinner) $spinner.show();
}

export function hideSpinner() {
    const $spinner = $('#preloader-overlay');
    if ($spinner) $spinner.hide();
}

/**
 * Invoked when a product image file is selected. The file is uploaded to the server.
 * On completion, we embed the newly created FileUpload ID into the product image form.
 *
 * @returns {*|jQuery}
 */
export const onFileInputChange = (thumbnailContainer = null) => $('input[type="file"]:not(.bound)').addClass('bound').change(function() {
    // check for a UUID for this file input
    const {uuid} = $(this).data();
    if (!uuid) return;
    // find the matching 'file_upload_id' input with the UUID
    const $fileUploadIdInput = $(`input.file-upload-id[data-uuid="${uuid}"]`);
    const {files} = this;
    if (!files || !files.length) return;
    const file = files.item(0);
    if (!file) return;
    showSpinner();
    const formData = new FormData();
    formData.append('file', file, file.name);
    // upload file
    $.ajax({
        method: 'POST',
        url: '/file_uploads',
        data: formData,
        processData: false,
        contentType: false,
        success: res => {
            const {file} = res || {},
                {id, url} = file || {};
            $fileUploadIdInput.val(id);
            // upload 'file_upload_id' input with new ID
            if (thumbnailContainer)
                $fileUploadIdInput.closest(thumbnailContainer).find('img.placeholder').attr('src', url);
            hideSpinner();
        },
        error: err => {
            console.error(err);
            hideSpinner();
        }
    })
});

export const csrfToken = () => $('meta[name="csrf-token"]').attr('content');

export const fetchPostHeaders = () => ({
    'X-CSRF-Token': csrfToken(),
    'Content-Type': 'application/json',
});

export const downloadBlob = (blob, name) => {
    const blobUrl = URL.createObjectURL(blob),
        link = document.createElement('a');
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);
};

/**
 * Updates tumbnail src when image input is changed
 *
 * @returns {*|jQuery}
 */
export const thumbOnFileChange = () =>  $('input[type="file"]:not(.bound-thumb)').addClass('bound-thumb').change(function() {
    console.log('hello');
    const { uuid } = $(this).data();
    if (!uuid) return;
    // find img with matching uuid
    const $img = $(`img[data-uuid="${uuid}"]`);
    if (!$img.length) return;
    const { files } = this;
    if (!files || !files.length) return;
    const file = files.item(0);
    const reader = new FileReader();
    reader.onload = function(e) { $img.attr('src', e.target.result) };
    reader.readAsDataURL(file);
});

export const orderStatusToLabel = (status, isCustomer = false) => {
    switch (status) {
        case ORDER_STATUSES.CREATING:
            return 'Creating';
        case ORDER_STATUSES.CREATED:
            return 'Created';
        case ORDER_STATUSES.REORDER:
            return 'Reorder';
        case ORDER_STATUSES.CUSTOMER_REVISION:
            return 'Customer Revision';
        case ORDER_STATUSES.DESIGN_REVISION:
            return 'Design Revision';
        case ORDER_STATUSES.AWAITING_APPROVAL:
            return 'Awaiting Approval';
        case ORDER_STATUSES.AWAITING_PAYMENT:
            return 'Awaiting Payment';
        case ORDER_STATUSES.APPROVED:
            return 'Approved';
        case ORDER_STATUSES.IN_PROGRESS:
            return 'In Progress';
        case ORDER_STATUSES.DENIED:
            return 'Denied';
        case ORDER_STATUSES.RECEIVE_GARMENTS:
            if (isCustomer) return 'In Progress';
            return 'Receive Garments';
        case ORDER_STATUSES.ORDER_GARMENTS:
            if (isCustomer) return 'In Progress';
            return 'Order Garments';
        case ORDER_STATUSES.ART_VECTORIZATION:
            if (isCustomer) return 'In Progress';
            return 'Art Vectorization';
        case ORDER_STATUSES.PRINTING:
            if (isCustomer) return 'In Progress';
            return 'Printing';
        case ORDER_STATUSES.READY_TO_SHIP:
            if (isCustomer) return 'In Progress';
            return 'Ready to Ship';
        case ORDER_STATUSES.SHIPPED:
            return 'Shipped';
        case ORDER_STATUSES.REJECTED:
            return 'Rejected';
        default:
            return status;
    }
};

export const statusOptions = (status, isCustomer = false) => {
    let opts = [];
    switch (status) {
        case ORDER_STATUSES.IN_PROGRESS:
            opts.push(ORDER_STATUSES.ORDER_GARMENTS);
            break;
        case ORDER_STATUSES.ORDER_GARMENTS:
            opts.push(ORDER_STATUSES.RECEIVE_GARMENTS);
            break;
        case ORDER_STATUSES.RECEIVE_GARMENTS:
            opts.push(ORDER_STATUSES.ART_VECTORIZATION);
            break;
        case ORDER_STATUSES.ART_VECTORIZATION:
            opts.push(ORDER_STATUSES.PRINTING);
            break;
        case ORDER_STATUSES.PRINTING:
            opts.push(ORDER_STATUSES.READY_TO_SHIP);
            break;
        case ORDER_STATUSES.READY_TO_SHIP:
            opts.push(ORDER_STATUSES.SHIPPED);
            break;
          case ORDER_STATUSES.REJECTED:
            opts.push(ORDER_STATUSES.AWAITING_APPROVAL);
            break;
        case ORDER_STATUSES.CREATED:
            if (isCustomer) {
                break;
            }
            opts = [
                ...opts,
                ...[
                    ORDER_STATUSES.DENIED,
                    ORDER_STATUSES.AWAITING_APPROVAL,
                ]
            ];
            break;
        case ORDER_STATUSES.REORDER:
            if (isCustomer) {
                break;
            }
            opts = [
                ...opts,
                ...[
                    ORDER_STATUSES.DENIED,
                    ORDER_STATUSES.AWAITING_APPROVAL,
                ]
            ];
            break;
        case ORDER_STATUSES.AWAITING_APPROVAL:
            if (isCustomer) {
                opts = [ ...opts, ... [
                    ORDER_STATUSES.DESIGN_REVISION,
                    ORDER_STATUSES.APPROVED,
                ] ];
                break;
            }
            opts = [ ...opts, ...[
                ORDER_STATUSES.DESIGN_REVISION,
                ORDER_STATUSES.DENIED,
            ] ];
            break;
        case ORDER_STATUSES.CUSTOMER_REVISION:
            if (isCustomer) {
                opts = [ ...opts, ...[ ORDER_STATUSES.CREATED ] ];
                break;
            }
            opts = [ ...opts, ...[ ORDER_STATUSES.ORDER_GARMENTS, ORDER_STATUSES.DENIED ] ];
            break;
        case ORDER_STATUSES.DESIGN_REVISION:
            if (isCustomer) {
                break;
            }
            opts = [ ...opts, ...[ ORDER_STATUSES.ORDER_GARMENTS, ORDER_STATUSES.DENIED ] ];
            break;
        default:
            break;
    }
    return opts.map(status => ({ value: status, label: orderStatusToLabel(status) }));
};

export const maybeShowTab = () => {
    const queryString = window.location.search,
        urlParams = new URLSearchParams(queryString),
        tab = urlParams.get('tab');
    if (tab && tab.length) $(`.nav-link[href="#${tab}-tab"]`).tab('show');
};

export const resizeDesignSVG = () => $('.design-svg-container').find('svg').css({
    height: '100%',
    width: '100%',
})